import { QuestionUserBehaviour } from '../interfaces/question';
import { Test } from '../interfaces/test';

export function getTestMaxPoint(test: Test): number {
  let maxPoints = 0;

  maxPoints += test.total_questions * (test.simulator_attributes?.score_correct || 0);
  if (test?.tests?.length) {
    for (const subtest of test.tests) {
      maxPoints += getTestMaxPoint(subtest);
    }
  }

  return maxPoints;
}

export function getQuestions(test: Test): Array<QuestionUserBehaviour> {
  let questions = new Array<QuestionUserBehaviour>();

  questions = questions.concat(test.questions_user_behaviour);
  for (const subtest of test.tests) {
    questions = questions.concat(getQuestions(subtest));
  }

  return questions;
}

export function isCorrect(question: QuestionUserBehaviour): boolean {
  return question.answer_correct?.includes(question.given_answer) || false;
}

export function isBlank(question: QuestionUserBehaviour): boolean {
  return question.given_answer === null || question.given_answer === undefined || question.given_answer === '';
}

export function isWrong(question: QuestionUserBehaviour): boolean {
  return !isBlank(question) && !isCorrect(question);
}
